import React, {useEffect, useRef} from 'react'
import './star.css'

const Star = ({radius, index}) => {

    const isFirst = useRef(true)
    const starRef = useRef()
    const SCALE = 0.15

    useEffect(() => {
        // if (isFirst.current) {
        //     isFirst.current = false
        // } else {
            // console.log("radius: ", radius);
            const star = starRef.current
            const randomZIndexChoices = new Array(10).fill(0).map((_, index) => index)
            const randomZIndex = randomZIndexChoices[Math.floor(Math.random() * randomZIndexChoices.length)];
            const randomPhotoIndices = new Array(17).fill(0).map((_, index) => index + 120)
            const randomPhotoIndex = randomPhotoIndices[Math.floor(Math.random() * randomPhotoIndices.length)];
            // console.log("randomPhotoIndex: ", randomPhotoIndex)
            star.style.backgroundImage = `url('stars/Celestial-items-Gold-${randomPhotoIndex}.png')`
            star.style.zIndex = randomZIndex.toString()
            star.style.transform = `rotate(${Math.random()*180}deg) scale(${SCALE*randomZIndex})`
            const randomTop  = (Math.random()-0.5)*4*radius
            const randomLeft = (Math.random()-0.5)*4*radius
            star.style.top = `${randomTop}px`
            star.style.left = `${randomLeft}px`
            const DepthBlurStart = 5
            const minBlur  = 0.2, maxBlur = 1
            const blurLevel = (DepthBlurStart - randomZIndex)*(maxBlur - minBlur) + minBlur
            star.style.filter = `blur(${blurLevel}px)`
        // }
    }, [])

    return (
        <div className={`rocket-star`} ref={starRef}></div>
    )
}


export default Star
