import React, {useState} from 'react';
import Rocket from './Rocket'
import CombGame from './Comb-Game'
import ColorCanvas from './ColorCanvas'
import './index.css'



const Main = () => {

    const [changePageState, setChangePageState] = useState(0)

    return (
        <>
        <div className="main-container">
            {changePageState === 0 ? <Rocket setChangePageState={setChangePageState} pageIndex={0}/> : 
            ( changePageState === 1 ?<CombGame setChangePageState={setChangePageState} pageIndex={1}/>
            : null
            // <ColorCanvas />
            )}

        </div>
        </>
    )
}


export default Main