import "./App.css";
// import NavBar from './components/NavBar'
import Main from './components/Main'
import { Analytics } from '@vercel/analytics/react';
import {useState, useEffect} from 'react'
import {webVitals} from './vitals'


function App() {

  useEffect(() => {
      let analyticsId = process.env.REACT_APP_VERCEL_ANALYTICS_ID
      if (analyticsId) {
        webVitals({path: window.location.pathname, analyticsId, params: {}})
      }
  }, [])

  return (
    <>
      <div className="container">
        {/* <NavBar /> */}
        <Main />
        <Analytics />
      </div>
    </>
  );
}

export default App;
