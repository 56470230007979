import React, { useEffect, useRef } from "react";
import "./index.css";
import * as d3 from "d3";
import Vector from "./vector";
import Mover from "./mover";
import Star from "./star";

// div {
//     height:100vh;
// }

// https://javascript.info/bubbling-and-capturing {capture: false} vs {capture: true} currentTarget vs target

const Rocket = ({setChangePageState}) => {
    const substitution = (string, newString, type) => {
        if (type === "translate") {
            if (string.search(/translate\((.*?)\)/) < 0) {
                if (string.search(/scale\((.*?)\)/) < 0) {
                    return newString;
                } else {
                    return string + newString;
                }
            } else {
                return string.replace(/translate\((.*?)\)/, newString);
            }
        } else {
            if (string.search(/scale\((.*?)\)/) < 0) {
                if (string.search(/translate\((.*?)\)/) < 0) {
                    return newString;
                } else {
                    return string + newString;
                }
            } else {
                return string.replace(/scale\((.*?)\)/, newString);
            }
        }
    };

    const isFirst = useRef(true);
    // const transformation = useRef(new DOMMatrix())

    const updateElementOnScroll = (element, scale) => {
        const newString = `scale(${scale(document.documentElement.scrollTop)})`;
        element.style.transform = element.style.transform
            ? substitution(element.style.transform, newString, "scale")
            : newString;
    }
    const handleOnScroll = useRef((e) => {
        const rocket = document.querySelector(".rocket-itself");
        const stars = document.querySelectorAll(".rocket-star");
        const scale = d3
            .scaleLinear()
            .domain([0, document.documentElement.scrollHeight - window.innerHeight])
            .range([1, 3]);
            updateElementOnScroll(rocket, scale)
        stars.forEach((star) => {
            updateElementOnScroll(star, scale)
        })
    });


    const movers = useRef();
    const numOfStars = 15

    const pageX = (elem) => window.scrollX + elem.getBoundingClientRect().left;
    const pageY = (elem) => window.scrollY + elem.getBoundingClientRect().top;

    const circleHandleOnMouseMove = useRef((e) => {
        e.stopPropagation();
    });

    const updateRocket = (e, pageXRocketCanvas,pageYRocketCanvas, element, index) => {
        const pageXElement = pageX(element), pageYElement = pageY(element)

        // find the offsets for mouse and the object
        const offsetX = pageXElement - pageXRocketCanvas;
        const offsetY = pageYElement - pageYRocketCanvas;
        const mouseOffsetX = e.clientX - pageXRocketCanvas;
        const mouseOffsetY = e.clientY - pageYRocketCanvas;

        // calculate the velocity vector
        let elementVector = new Vector(offsetX, offsetY, 0);
        let mouseVector = new Vector(mouseOffsetX, mouseOffsetY, 0);
        const requiredVector = elementVector.subtract(mouseVector);
        const unitVector = requiredVector.unit();
        const mover = movers.current[index]
        mover.update(unitVector);

        // update style of the object
        const scale = 0.04;
        const newString = `translate(${scale * mover.position.x}px, ${scale * mover.position.y}px)`;
        element.style.transform = substitution(
            element.style.transform,
            newString,
            "translate"
        );
    }

    
    const updateStar = (e, pageXRocketCanvas,pageYRocketCanvas, element, index) => {
        const pageXElement = pageX(element), pageYElement = pageY(element)

        // find the offsets for mouse and the object
        const offsetX = pageXElement - pageXRocketCanvas;
        const offsetY = pageYElement - pageYRocketCanvas;
        const mouseOffsetX = e.clientX - pageXRocketCanvas;
        const mouseOffsetY = e.clientY - pageYRocketCanvas;

        // calculate the velocity vector
        let elementVector = new Vector(offsetX, offsetY, 0);
        let mouseVector = new Vector(mouseOffsetX, mouseOffsetY, 0);
        const requiredVector = elementVector.subtract(mouseVector);
        const unitVector = requiredVector.unit();
        const mover = movers.current[index]
        mover.update(unitVector);

        // update style of the object
        let scaleX = 0.0001;
        let scaleY = 0.00012;
        scaleX = Math.sqrt(element.style.zIndex)*scaleX
        scaleY = Math.sqrt(element.style.zIndex)*scaleY
        // const newString = `translate(${scale * mover.position.x}px, ${scale * mover.position.y}px)`;
        const newString = `translate(${scaleX * mover.position.x}px, ${scaleY* mover.position.y}px)`;
        element.style.transform = substitution(
            element.style.transform,
            newString,
            "translate"
        );
    }

    const handleOnMouseMove = useRef((e) => {
        console.log("handleOnMouseMove:", e.currentTarget);
        console.log("handleOnMouseMove:", e.currentTarget.className);
        console.log("canvas event received!");
        const rocketCanvas = document.querySelector(".rocket-canvas");
        const pageXRocketCanvas = pageX(rocketCanvas), pageYRocketCanvas = pageY(rocketCanvas);

        // find pageX and pageY for the object
        const rocket = document.querySelector(".rocket-itself");
        const stars = document.querySelectorAll(".rocket-star");

        updateRocket(e, pageXRocketCanvas, pageYRocketCanvas, rocket, 0)
        stars.forEach((star, index) => {
            updateStar(e, pageXRocketCanvas, pageYRocketCanvas, star, index + 1)
        })
    });

    const handleOnClick = (e) => {
        const targetElement = e.target
        if (targetElement.className === "btn navigate") {
            setChangePageState(1)
        }
    }

    useEffect(() => {
        // if (isFirst.current) {
        //     isFirst.current = false;
        // } else {
            const rocketCanvas = document.querySelector(".rocket-canvas");
            const rocket = document.querySelector(".rocket-itself");
            const circle = document.querySelector(".circle");

            movers.current = new Array(numOfStars + 1).fill(0).map((ele) => new Mover()) 

            window.addEventListener("scroll", handleOnScroll.current, {
                capture: false,
            });
            circle.addEventListener("mousemove", circleHandleOnMouseMove.current, {
                capture: false,
            });
            rocketCanvas.addEventListener("mousemove", handleOnMouseMove.current, {
                capture: false,
            });

            return () => {
                window.removeEventListener("scroll", handleOnScroll.current, {
                    capture: false,
                });
                circle.removeEventListener(
                    "mousemove",
                    circleHandleOnMouseMove.current,
                    { capture: false }
                );
                rocketCanvas.removeEventListener(
                    "mousemove",
                    handleOnMouseMove.current,
                    { capture: false }
                );
            };
        // }
    }, []);

    return (
        <>
            <div className="rocket-container" onClick={handleOnClick}>
                <div className="rocket-wrapper-outer">
                    <div className="rocket-canvas">
                        <div className="circle">
                            <div className="inner-circle">
                                <div className="rocket-wrapper">
                                    <div className="rocket-itself"></div>
                                </div>
                            </div>
                            {new Array(numOfStars).fill(0).map((ele, index) => (
                                <Star key={index} radius={220} index={index} />
                            ))}
                        </div>
                    <button className="btn navigate">Next</button>    
                    </div>
                    {/* <div className="rocket-description">
                        <button className="btn navigate">Next</button>
                        <div className="rocket-description-box">
                            <h1 className="rocket-description-heading">Mathematics</h1>
                            <h2 className="rocket-description-words">Coordinates, Vectors, Projections</h2>
                        </div>
                        <div className="rocket-description-box">
                            <h1 className="rocket-description-heading">Javascript</h1>
                            <h2 className="rocket-description-words">Events</h2>
                        </div>
                    </div> */}
                </div>
                    
            </div>
        </>
    );
};

export default Rocket;
