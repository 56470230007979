import React from "react";
import "./DiceCup.css";

const DiceCup = ({id, text, color, ballType, isActive, onAnswerSubmit, state, flow}) => {

  const handleOnClick = () => {
    if (state === 2) {
      onAnswerSubmit(id)
      flow(3, id)
    }
  }

  return (
    <div className="game-dice-cup-container" id={id} onClick={handleOnClick}>
      <div className={ballType === 'white' ? (isActive ? "game-white-ball game-is-active" : "game-white-ball") : (isActive ? "game-blue-ball game-is-active" : "game-blue-ball")}></div>
        <div className="game-dice-cup">
            <div className="game-dice-text" style={{color}}>{text}</div>
        </div>
    </div>
  );
};

export default DiceCup;
