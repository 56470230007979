import React, {useState, useEffect, useRef} from 'react';
import DiceCup from './DiceCup/DiceCup'
import "./index.css"


// const combinationList = [
//     ["A", "B"],
//     ["A", "C"],
//     ["A", "D"],
//     ["B", "C"],
//     ["B", "D"],
//     ["C", "D"]
// ]


const Main = ({setChangePageState}) => {

    const SINGLE_DELAY = 200
    const DELAY_OFFSET = 500
    const ASSIGN_BALL_TIME = 2000


    const isFirst = useRef(false)
    const [isRendering, setIsRendering] = useState(false)
    const [isHidden, setIsHidden] = useState(true)

    const elementList = ["A", "B", "C", "D"]
    const colorList = ["white", "white", "white", "blue"]
    const [subjectMapToInfo, setSubjectMapToInfo] = useState({
        A: {color: "#4E7B8A", ballType: 'blue'},
        B: {color: "#4E5E96", ballType: 'white'},
        C: {color: "#354270", ballType: 'white'},
        D: {color: "#314D57", ballType: 'white'},})
    
    const [combinationList, setCombinationList] = useState([])

    const [answer, setAnswer] = useState("")
    const [selectedAnswer , setSelectedAnswer] = useState("")
    const [state, setState] = useState(0)
    const [text, setText] = useState("play")

    const handleOnClickBack = () => {
        setChangePageState(0)
    }

    useEffect(() => {
        // if (isFirst.current === false) {
        //     isFirst.current = true
        // } else {
            // create a random order list
            let orderList = generateRandomOrder(elementList);
            setCombinationList(orderList);
            // assign 3 blue, 1 white balls into different bottle
            assignToDifferentBottle();
        // }
    }, [])

    // right now is non-responsive, I need to make it as responsive
    // consider limit the click count

    const runAnimation = (flow) => {
        // console.log("Comb-Game: [runAnimation]")
        setIsRendering(true)
        setIsHidden(false)
        setSelectedAnswer("")
        let count = 0
        let distanceWalked = {
            A: {x: 0, y: 0},
            B: {x: 0, y: 0} ,
            C: {x: 0, y: 0}, 
            D: {x: 0, y: 0}
        }    
        elementList.forEach((char) => {
            const item = document.querySelector(`#game-${char}`)
            item.style.transform = `translate(${0}px, ${0}px)`;
        })

        // console.log("Combin Game: runAnimation", elementList)

        assignToDifferentBottle()

        setTimeout(() => {

            setIsHidden(true)
    
            combinationList.forEach((items, index) => {
                count +=1
                setTimeout(() => {
                    
                    const item1Char = items[0]
                    const item2Char = items[1]
                    const item1 = document.querySelector(`#game-${item1Char}`)
                    const item2 = document.querySelector(`#game-${item2Char}`)
                    item1.className = "game-dice-cup-container";
                    item2.className = "game-dice-cup-container";
                    let {x: item1OriX, y: item1OriY} = item1.getBoundingClientRect();
                    let {x: item2OriX, y: item2OriY} = item2.getBoundingClientRect();
                    const diffX = item2OriX - item1OriX;
                    const diffX_Absolute = Math.abs(diffX)
                    // console.log("diffX", diffX_Absolute)
                    if (diffX > 0) {
                        // A.x is smaller than B.x, therefore A should swap right and B should swap left
                        distanceWalked[item1Char].x += diffX_Absolute
                        distanceWalked[item2Char].x -= diffX_Absolute
                        item1.className = "game-dice-cup-container game-swap-right";
                        item2.className = "game-dice-cup-container game-swap-left";
                        item1.style.transform = `translate(${distanceWalked[item1Char].x}px, ${distanceWalked[item1Char].y}px)`;
                        item2.style.transform = `translate(${distanceWalked[item2Char].x}px, ${distanceWalked[item2Char].y}px)`;
                    } else {
                        // // console.log("swapped");
                        // A.x is larger than B.x, therefore A should swap left and B should swap right
                        distanceWalked[item1Char].x -= diffX_Absolute
                        distanceWalked[item2Char].x += diffX_Absolute
                        item1.className = "game-dice-cup-container game-swap-left";
                        item2.className = "game-dice-cup-container game-swap-right";
                        item1.style.transform = `translate(${distanceWalked[item1Char].x}px, ${distanceWalked[item1Char].y}px)`;
                        item2.style.transform = `translate(${distanceWalked[item2Char].x}px, ${distanceWalked[item2Char].y}px)`;
                        }
                    
                    const diffY = item2OriY - item1OriY
                    const diffY_Absolute = Math.abs(diffY)                    
                    if (diffY > 0) {
                        // A.x is smaller than B.x, therefore A should swap right and B should swap left
                        distanceWalked[item1Char].y += diffY_Absolute
                        distanceWalked[item2Char].y -= diffY_Absolute
                        item1.className = "game-dice-cup-container game-swap-right";
                        item2.className = "game-dice-cup-container game-swap-left";
                        item1.style.transform = `translate(${distanceWalked[item1Char].x}px, ${distanceWalked[item1Char].y}px)`;
                        item2.style.transform = `translate(${distanceWalked[item2Char].x}px, ${distanceWalked[item2Char].y}px)`;
                    } else {
                        // // console.log("swapped");
                        // A.x is larger than B.x, therefore A should swap left and B should swap right
                        distanceWalked[item1Char].y -= diffY_Absolute
                        distanceWalked[item2Char].y += diffY_Absolute
                        item1.className = "game-dice-cup-container game-swap-left";
                        item2.className = "game-dice-cup-container game-swap-right";
                        item1.style.transform = `translate(${distanceWalked[item1Char].x}px, ${distanceWalked[item1Char].y}px)`;
                        item2.style.transform = `translate(${distanceWalked[item2Char].x}px, ${distanceWalked[item2Char].y}px)`;
                        }
                    }
                , (index)*SINGLE_DELAY + DELAY_OFFSET + ASSIGN_BALL_TIME)
            })
    
            setTimeout(() => {
                setIsRendering(false)
                setIsHidden(true)
                flow(2)
            }, (count)*SINGLE_DELAY + DELAY_OFFSET + ASSIGN_BALL_TIME)
        }, ASSIGN_BALL_TIME)
    }

    const selectTwoElements = (array) => {
        const shuffled = array.sort(() => 0.5 - Math.random())
        return shuffled.slice(0,2);
    }

    const onAnswerSubmit = (selectedAns) => {
        // console.log(`selectedAns: ${selectedAns}|answer: ${answer}`)
        setSelectedAnswer(selectedAns)
    }

    const generateRandomOrder = (array) => {
        const iterations = Math.floor(Math.random()*5) + 20
        let previsousSelected = []
        let finalArray = new Array(iterations).fill(0).map((item, index) => {
            while (true) {
                if (index === 0) {
                    let selected = selectTwoElements(array)
                    previsousSelected = [...selected]
                    return selected
                } else {
                    let selected = selectTwoElements(array)
                    const isduplicated = selected.every((selectedItem , index) => selectedItem === previsousSelected[index])
                    if (isduplicated) {
                        // repeat while loop
                    } else {
                        previsousSelected = [...selected]
                        return selected
                    }
                }
            }
        })
        return finalArray
    }

    const assignToDifferentBottle = () => {
        let copiedSubjectMapToInfo = {...subjectMapToInfo}
        let sortedColorList = colorList.sort(() => 0.5 - Math.random())
        elementList.forEach((ele, index) => {
            copiedSubjectMapToInfo[ele].ballType = sortedColorList[index]
            if (sortedColorList[index] === "blue") setAnswer(`game-${ele}`)
        })
        setSubjectMapToInfo(copiedSubjectMapToInfo)
    }

    const flow = (state, selectedAns="") => {
        // console.log("Comb-Game: [flow]")
        if (state === 0) {
            setText("play")
            setState(state)
        } else if (state === 1) {
            setText("playing...")
            let orderList = generateRandomOrder(elementList)
            // console.log(orderList)
            setCombinationList(orderList)
            runAnimation(flow)
            setState(state)
        } else if (state ===2) {
            setText("guess")
            setState(state)
        } else if (state ===3) {
            // console.log(answer === selectedAns)
            setText(answer === selectedAns ? "correct" : `incorrect` )
            setState(state)
        }
    }


    const handleOnClick = () => {
        // console.log("Comb-Game: [handleOnClick]")
        switch (state) {
            case -1:
                flow(0);
                break;
            case 0:
                flow(1);
                break;
            case 3:
                flow(0);        
                break;
        }
    }

    return (
        <>
        <main className="game-bag-container">
            <div className="game-bag-box">
                <DiceCup id={"game-A"}
                // text={"A"} 
                color={subjectMapToInfo.A.color} ballType={subjectMapToInfo.A.ballType} isActive={isHidden} onAnswerSubmit={onAnswerSubmit} state={state} flow={flow}/>
                <DiceCup id={"game-B"}
                // text={"B"} 
                color={subjectMapToInfo.B.color} ballType={subjectMapToInfo.B.ballType} isActive={isHidden} onAnswerSubmit={onAnswerSubmit} state={state} flow={flow}/>
                <DiceCup id={"game-C"}
                // text={"C"} 
                color={subjectMapToInfo.C.color} ballType={subjectMapToInfo.C.ballType} isActive={isHidden} onAnswerSubmit={onAnswerSubmit} state={state} flow={flow}/>
                <DiceCup id={"game-D"}
                // text={"D"} 
                color={subjectMapToInfo.D.color} ballType={subjectMapToInfo.D.ballType} isActive={isHidden} onAnswerSubmit={onAnswerSubmit} state={state} flow={flow}/>
            </div>
            {/* <div className="click-text">{combinationRightNow? combinationRightNow: "{}"}</div> */}
            <div className={isRendering || state === 1 || state === 2 ? "game-click-text game-disable" : "game-click-text"} onClick={handleOnClick}>{text}</div>
            <button className="btn-game navigate-game" onClick={handleOnClickBack}>LAST</button>
        </main>
        </>
    )
}

export default Main