import Vector from './vector'

class Mover {
    constructor() {
        this.position = new Vector(0,0, 0)
        this.velocity = new Vector(0,0, 0)
    }

    update(v) {
        this.velocity = this.velocity.add(v)
        this.position = this.position.add(this.velocity)
    }
}

export default Mover